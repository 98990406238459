<template>
  <div class="ysf-box">
    <div class="ysf-box1">
        <div class="ysf-box1-top">
            <span>云闪付订单</span>
            <img src="../../assets/yun-icon.png" alt="yun-icon.png">
        </div>
        <div class="ysf-box2-bottom">
            <span>￥</span>
            <van-field v-model="upayForm.amount"  type="number" maxlength="12" ref="amount" class="ysf-input" placeholder="请输入金额" />
        </div>
    </div>
    <div class="ysf-box2">
      <van-button type="primary" class="ysf-pay-btn" @click="toPlay()">付款</van-button>
    </div>
  </div>
</template>

<script>
import './YsfpayTest.css';
import UUID from 'uuidjs';
import { Dialog } from 'vant';

export default {
  data() {
    return {
      upayForm: {
          mchNo: "",
          appId: "",
          mchOrderNo: "",
          amount: '',
          subject: "云闪付订单",
          body: "云闪付订单",
          notifyUrl: "https://mch.uipay.cn/api/anon/paytestNotify/payOrder",
          returnUrl: "",
          divisionMode: 0,
          wayCode: ""
      },
    }
  },
  created() {
    this.upayForm.mchNo = this.$route.query.mchNo;
    this.upayForm.appId = this.$route.query.appId;
    this.upayForm.mchOrderNo = `ysforder-${UUID.genV4().hexFields.node}`;
  },
  methods: {
    toPlay() {
        if (this.upayForm.amount) {
          const params = { ...this.upayForm, amount: Number(this.upayForm.amount) * 100 };
          const paramsArr = Object.keys(params).map(key => `${key}=` + params[`${key}`] );
          document.location.href = "https://pay.uipay.cn/api/pay/qrCashierOrder?" + paramsArr.join('&');
        } else {
          Dialog.alert({
            title: '提示',
            message: '请输入支付金额',
            theme: 'round-button',
          }).then(() => {});
        }
    }
  }
}
</script>

<style>

</style>